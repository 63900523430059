import {useMUIDialog} from '../../../@fuse/hooks';
import {DialogTitle, Typography} from '@material-ui/core';
import {GDialogRegistries} from '../../../@fuse/state/constant';
import {useTranslation} from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {useState} from 'react';
import usePutLockRequest from './hooks/usePutLockRequest';
import * as fuseActions from '@fuseActions';
import {useDispatch} from 'react-redux';
import RootDialogController from '../../../utils/RootComponents/PageController/RootDialogController';

function CancelLockDialog({}) {

    const {t} = useTranslation("_");

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const {run} = usePutLockRequest();

    const {dialogProps,extraInfo,onTurnOffDialog,finshParams} = useMUIDialog({
        registry: GDialogRegistries.cancelLock,
    });


    const handleOk = (e) => {
        e.stopPropagation();
        const params = {
            "incomeIds": extraInfo.selectedRows
        }
        setLoading(true);
        run(params).then((result) => {
            setLoading(false);
            if (result.status === 200){
                finshParams?.onFinish();
                dispatch(fuseActions.showMessage({message: "_.Cancel Lock income success", variant: "success"}));
                onTurnOffDialog();
            }
        })
    }


    return (
        <RootDialogController
            open={dialogProps.open}
            onClose={()=>{ onTurnOffDialog() }}
        >
            <DialogTitle>
                {t("Cancel lock income")}
            </DialogTitle>
            <div className={loading ? "loading" : ""}>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary">
                        {t("Do you want to cancel the selected locked incomes?")}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {/*<Button variant={"contained"} color={"secondary"} size={"small"} onClick={handleClose}>*/}
                    {/*    {t("close")}*/}
                    {/*</Button>*/}
                    <Button variant={"contained"} color={"primary"} size={"small"} onClick={handleOk}>
                        {t("cancel lock")}
                    </Button>
                </DialogActions>
            </div>
        </RootDialogController>
    );
}

export default CancelLockDialog;

