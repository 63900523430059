// @ts-nocheck
import {forwardRef, useMemo} from "react";
import cloneDeep from "lodash/cloneDeep";
import {useMemoizedFn} from "ahooks";
import {indexOf} from "lodash";
import {useMUIStore} from "../state";
import Fade from "@material-ui/core/Fade";
import {GDialogRegistries} from "../state/constant";
import Transition from './utils';



// const Transition = forwardRef(function Transition(
//     props: TransitionProps & { children?: ReactElement<any, any> },
//     ref: Ref<unknown>
// ): Fade {
//     // @ts-ignore
//     return <Fade {...props}  ref={ref} in   />;
// });

/**
 @description - dialog props
 * @param {any} options -  useMUIDialog 的 props
 * @param {boolean} options.open - 打开 true OR 关闭 false
 * @param {boolean} options.fullScreen - 全屏展示
 * @param {boolean} options.keepMounted - 始终将 children 留在DOM中。该属性在SEO情况下或当您想最大限度地提高 Dialog 的响应能力时很有用。
 * @param {boolean} options.fullWidth - maxWidth 全宽展示
 * @param {String} options.maxWidth - 'lg'| 'md'| 'sm'| 'xl'| 'xs'| false
 * @param {elementType} options.TransitionComponent - 动画 , 默认 Fade animation
 * @param {function} options.onClose - 关闭 dialog
 * @param {GDialogRegistries} options.registry - 非 dialog props , Dialog 的全局注册表,全局注册表中有该 tag 标识打开，无则关闭
 * @returns {Object} results - results.dialogProps - dialog props || results.onTurnOnDialog - 打开 dialog || results.onTurnOffDialog - 关闭 daialog
 */

export interface MyDialogProps {
    open?: boolean;
    fullScreen?: boolean;
    keepMounted?: boolean;
    fullWidth?: boolean;
    maxWidth?: string;
    TransitionComponent?: any;
    onClose?: () => void;
    registry?: GDialogRegistries;
}

function useMUIDialog(options: MyDialogProps) {

    const dialogRegistries = useMUIStore((state) => state.dialogRegistries);

    const config = useMUIStore((state) => state.config);

    const finshParams = useMUIStore((state) => state.finshParams);

    const extraInfo = useMUIStore((state) => state.extraInfo);

    const onChangeExtraInfo = useMUIStore((state) => state.onChangeExtraInfo);

    const onChangeFinshParams = useMUIStore((state) => state.onChangeFinshParams);

    const onChangeConfig = useMUIStore((state) => state.onChangeConfig);

    const onChangeDialogRegistries = useMUIStore((state) => state.onChangeDialogRegistries);

    /**
     * @param {Object} options
     * @param {function} options.onFinsh - 当 dialog 成功执行完所有请求后，进行的刷新操作， 比如 list 或者 single 的刷新
     */
    const onTurnOnDialog = useMemoizedFn((params = null) => {
        wrapPropsFunc();
        if (!!params) {
            // @ts-ignore
            const _params = {[options.registry]: params};
            onChangeFinshParams(_params);
        }
        indexOf(dialogRegistries, options.registry) === -1 && onChangeDialogRegistries(options.registry);
    });

    const onTurnOffDialog = useMemoizedFn(() => {
        indexOf(dialogRegistries, options.registry) !== -1 && onChangeDialogRegistries(options.registry);
    });

    const open = useMemo(() => {
        return indexOf(dialogRegistries, options?.registry) !== -1;
    }, [dialogRegistries, options.registry]);

    const wrapPropsFunc = () => {
        const _dialogProps = cloneDeep(options);
        let originConfig: any;
        // @ts-ignore
        if (!!config && !!config[_dialogProps.registry]) {
            // @ts-ignore
            originConfig = config[_dialogProps.registry];
        } else {
            originConfig = {
                TransitionComponent: Transition,
                transitionDuration: 500,
                keepMounted: true,
                scroll: "paper",
                PaperProps: {
                    overflow: "visible",
                },
                className: "overflow-hidden",
            };
        }

        if (!!options?.fullWidth) {
            originConfig = {
                ...originConfig,
                fullWidth: _dialogProps.fullWidth,
            };
        }
        if (!!options?.keepMounted) {
            originConfig = {
                ...originConfig,
                keepMounted: _dialogProps.keepMounted,
            };
        }
        if (!!options?.fullScreen) {
            originConfig = {
                ...originConfig,
                fullScreen: _dialogProps.fullScreen,
            };
        }
        if (!!options?.maxWidth) {
            originConfig = {
                ...originConfig,
                maxWidth: _dialogProps.maxWidth,
            };
        }
        originConfig = {
            ...originConfig,
            onClose: onTurnOffDialog,
            open: open,
        };
        let resultConfig: any;
        if (config) {
            // @ts-ignore
            // @ts-ignore
            resultConfig = {
                ...config,
                [_dialogProps.registry]: originConfig,
            };
        } else {
            // @ts-ignore
            // @ts-ignore
            resultConfig = {
                [_dialogProps.registry]: originConfig,
            };
        }
        onChangeConfig(resultConfig);
    };

    return useMemo(() => {
        // @ts-ignore
        const tempDialogProps = config ? {...config[options.registry], open} : {};
        // @ts-ignore
        // @ts-ignore
        return {
            open: open,
            finshParams: finshParams ? finshParams[options.registry] : {},
            dialogProps: tempDialogProps,
            onTurnOnDialog,
            onTurnOffDialog,
            extraInfo,
            onChangeExtraInfo,
        };
    }, [open, finshParams, options.registry, config, onTurnOnDialog, onTurnOffDialog]);
}

export default useMUIDialog;
