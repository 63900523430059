import {createContext, useContext} from "react";
import type {SearchType} from "@types";
import type {SWRResponse} from "swr";

export type FiltersContextType = {
    filters: SearchType;
    addFilter: (filter: SearchType) => void;
    removeFilter: (filter: string | string[]) => void;
    clearFilters: () => void;
    replaceFilters: (filters: SearchType) => void;
    hasFilters: boolean;
    totalPages: number;
    totalElements: number;
    swr: SWRResponse;
};

export const FiltersContext = createContext<FiltersContextType>({
    filters: {},
    addFilter: () => {},
    removeFilter: () => {},
    clearFilters: () => {},
    replaceFilters: () => {},
    hasFilters: false,
    totalPages: 0,
    totalElements: 0,
    swr: {
        data: undefined,
        error: undefined,
        mutate: function (): Promise<any> {
            throw new Error("Function not implemented.");
        },
        isValidating: false,
        isLoading: false,
    },
});

export function useFilters() {
    return useContext(FiltersContext);
}
