
import axios, {financialPrefix} from "myaxios";
import useSWRMutation from "swr/mutation";

function sendRequest(url, {arg}) {
    return axios.put(url, arg);
}

function usePutLockRequest() {
    const {trigger, isMutating, data, error} = useSWRMutation(`${financialPrefix}/cancelLocked`, sendRequest);

    return {
        run: trigger,
        isMutating,
        data,
        error,
    };
}

export default usePutLockRequest;
