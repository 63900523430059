import {FusePageSimple} from "../../../../../../@fuse";
import NewMatchingHeader from "./NewMatchingHeader";
import NewSubContent from "./NewSubContent";
import {useMUIDialog} from "../../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../../@fuse/state/constant";
import NewSelectedMatchingDistributeDialog from "../../NewSelectedMatchingDistributeDialog";

function NewMatchingDialogContent(props) {
    const selectedMatchingDistributeActions = useMUIDialog({
        registry: GDialogRegistries.selectedMatchingDistribute,
        maxWidth: 'md',
    });

    return (
        <>
            <FusePageSimple
                classes={{
                    content: "flex flex-row flex-1",
                    header: "w-full h-52 sm:h-136 sm:min-h-136",
                }}
                header={<NewMatchingHeader />}
                content={<NewSubContent />}
            />
            {selectedMatchingDistributeActions?.open && <NewSelectedMatchingDistributeDialog />}
        </>
    );
}

export default NewMatchingDialogContent;
