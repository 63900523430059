export const baseURL = "https://api.signabc.com";

export const version = "0.5.2";
// export const mockSCM = 'http://127.0.0.1:3401';
// export const mockPms = 'http://127.0.0.1:3402';
// export const baseURL =  "https://api.signabc.com";

export const baseChatURL = "https://cs.signabc.com";
// export const baseChatURL =  `https://cs.signabc.com`;

// export const baseURL = 'http://192.168.0.43:8719';
// export const baseURL = 'https://api.signabc.com';
export const homepageURL = "/dashboard";
export const fileSeverURL = "https://oss.signabc.com";

//Please notify me after modifying this file. by qwang
//Can be add new content after 3th line
