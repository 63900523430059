import {DialogWrapper} from "@ui";
import {useSelector} from "react-redux";
import MyDialog from "utils/MyDialog/MyDialog";
import withDialog from "utils/withDialog";
import DashboardDialog from "../dashboards/main/DashboardDialog";
import FullImage from "../layouts/shared-components/FullImage";
import QrCodes from "../layouts/shared-components/QrCodes";
import CreateUserTask from "../main/camunda-process/management/dialogs/CreateUserTask";
import DefinitionDialog from "../main/camunda-process/management/dialogs/DefinitionDialog";
import DeleteVariableTips from "../main/camunda-process/management/dialogs/DeleteVariableTips";
import EndormentDialog from "../main/camunda-process/management/dialogs/EndormentDialog";
import ReStartPauseInstanceDialog from "../main/camunda-process/management/dialogs/ReStartPauseInstanceDialog";
import StartPauseDefinitionDialog from "../main/camunda-process/management/dialogs/StartPauseDefinitionDialog";
import TransferSingleForInstance from "../main/camunda-process/management/dialogs/TransferSingleForInstance";
import UserActivityProcessDialog from "../main/camunda-process/management/dialogs/UserActivityProcessDialog";
import VariableDialog from "../main/camunda-process/management/dialogs/VariableDialog";
import HelpCenterDialog from "../main/camunda-process/utils/ui/HelpCenterDialog";
import ViewMuiltTaskInAStepDialog from "../main/camunda-process/utils/ui/ViewMuiltTaskInAStepDialog";
import BrandEditDialog from "../main/commerce/brands/BrandDialog";
import EditCategoryDialog from "../main/commerce/brands/categories/BrandCategoryDialog";
import CustomerEditDialog from "../main/commerce/customers/single/CustomerDialog";
import ProductEditDialog from "../main/commerce/products/single/ProductDialog";
import ImportProjectDialog from "../main/commerce/projects/Dialogs/ImportProjectDialog";
import SolutionDialog from "../main/commerce/solutions/SolutionDialog";
import CheckinResultsPage from "../main/external_management/management/checkinResults/CheckinResultsPage";
import IssueDialog from "../main/issues/dialogs/IssueDialog";
import ActivityPage from "../main/marketing/activities/activity/SinglePage";
import ActivityDialog from "../main/marketing/activities/dialogs/ActivityDialog";
import SaleExpenseDialog from "../main/marketing/activities/dialogs/SaleExpenseDialog";
import ArchiveDialog from "../main/marketing/Archives/dialogs/ArchiveDialog";
import ArchiveDetailPage from "../main/marketing/Archives/single/SinglePage";
import CompanyPage from "../main/marketing/companies/company/SinglePage";
import CompanyDialog from "../main/marketing/companies/dialogs/CompanyDialog";
import CompanyContact from "../main/marketing/contacts/contact/SinglePage";
import CompanyContactDialog from "../main/marketing/contacts/dialogs/CompanyContactDialog";
import DealPage from "../main/marketing/deals/deal/SinglePage";
import DealDialog from "../main/marketing/deals/dialogs/DealDialog";
import MeetingDialog from "../main/marketing/meetings/dialogs/MeetingDialog";
import marketingDialogWrapper from "../main/marketing/utils/marketingDialogWrapper";
import MarketingLabelDialog from "../main/marketing/utils/MarketingLabelDialog";
import WPPage from "../main/workplans/WP/WPPage";
import WPDialog from "../main/workplans/WPDialog/WPDialog";
import TwoFactorWaiting from "./TwoFactorWaiting";
import AutoUpgradeDialog from '../../utils/CheckUpdateHelper/AutoUpgradeDialog';

const WPDetailDialog = withDialog("wpDetail", "xl")(WPPage);
const CheckinResultsDialog = withDialog("checkinResults", "xl")(CheckinResultsPage);
const WrapCompanyPage = marketingDialogWrapper("companyDetail", "companies")(CompanyPage);
const WrapCompanyContactPage = marketingDialogWrapper("contactDetail", "companyContacts")(CompanyContact);
const WrapActivityPage = marketingDialogWrapper("activityDetail", "activities")(ActivityPage);
const WrapDealPage = marketingDialogWrapper("dealDetail", "deals")(DealPage);
const WrapArchivePage = marketingDialogWrapper("archiveDetail", "archives", "md")(ArchiveDetailPage);

const CommonDialogs = () => {
    const dialogs = useSelector(({fuse}) => fuse.dialogs);
    const process_util = useSelector(({fuse}) => fuse.process_util);

    return (
        <>
            <DashboardDialog />
            {dialogs["twoFactorWaiting"] && <TwoFactorWaiting />}
            {dialogs["MyDialog"] && <MyDialog />}
            {dialogs["brand"] && <BrandEditDialog />}
            {dialogs["downloadQRCodes"] && <QrCodes />}
            {dialogs["customer"] && <CustomerEditDialog />}
            {dialogs["product"] && <ProductEditDialog />}
            {dialogs["editBrandCat"] && <EditCategoryDialog />}
            {dialogs["fullImage"] && <FullImage />}
            {dialogs["solution"] && <SolutionDialog />}
            {dialogs["archive"] && <ArchiveDialog />}
            {dialogs["CreateUserTask"] && <CreateUserTask />}
            {dialogs["VariableDialog"] && <VariableDialog />}
            {dialogs["DeleteVariableTips"] && <DeleteVariableTips />}
            {dialogs["ReStartPauseInstanceDialog"] && <ReStartPauseInstanceDialog />}
            {dialogs["PauseDefinitionTipsDialog"] && <StartPauseDefinitionDialog />}
            {dialogs["DefinitionDialog"] && <DefinitionDialog />}
            {dialogs["EndormentDialog"] && <EndormentDialog />}
            {dialogs["TransferSingleForInstance"] && <TransferSingleForInstance />}
            {process_util.dialog && <UserActivityProcessDialog />}
            {dialogs["wpDetail"] && <WPDetailDialog />}
            <DialogWrapper maxWidth={"lg"} name={"issue"}>
                {(onClose) => <IssueDialog onClose={onClose} />}
            </DialogWrapper>
            {dialogs["activity"] && <ActivityDialog />}
            {dialogs["company"] && <CompanyDialog />}
            {dialogs["deal"] && <DealDialog />}
            {dialogs["meeting"] && <MeetingDialog />}
            {dialogs["marketingLabel"] && <MarketingLabelDialog />}
            {dialogs["contact"] && <CompanyContactDialog />}
            {<WPDialog />}
            {dialogs["salesExpense"] && <SaleExpenseDialog />}
            {dialogs["companyDetail"] && <WrapCompanyPage />}
            {dialogs["contactDetail"] && <WrapActivityPage />}
            {dialogs["activityDetail"] && <WrapCompanyContactPage />}
            {dialogs["dealDetail"] && <WrapDealPage />}
            {dialogs["archiveDetail"] && <WrapArchivePage />}
            {dialogs["importProject"] && <ImportProjectDialog />}
            {dialogs["checkinResults"] && <CheckinResultsDialog />}
            <HelpCenterDialog />
            <ViewMuiltTaskInAStepDialog />
            {/*<VersionUpdateConfirm />*/}
            <AutoUpgradeDialog />

            {/*{dialogs["project"]                     && <ProjectEditDialog />}*/}
            {/*{dialogs["template"]                    && <TemplateDialog />}*/}
        </>
    );
};

export default CommonDialogs;
