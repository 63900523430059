import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {useMUIDialog} from '../../../../@fuse/hooks';
import {GDialogRegistries} from '../../../../@fuse/state/constant';
import {DialogTitle, Typography} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import usePutSetLockIncomes from './hooks/usePutSetLockIncomes';
import {useIncomeSureUtils} from '../IncomeSureManager/state';
import {map} from 'lodash';
import * as fuseActions from '@fuseActions';
import {useDispatch} from 'react-redux';
import RootDialogController from '../../../../utils/RootComponents/PageController/RootDialogController';

function NewLockIncomesDialog({}) {
    const {t} = useTranslation("_");

    const [loading, setLoading] = useState(false);

    const {run} = usePutSetLockIncomes();

    const dispatch = useDispatch();

    const {incomeSureListSelected} = useIncomeSureUtils();

    const {dialogProps,onTurnOffDialog,finshParams} = useMUIDialog({
        registry: GDialogRegistries.lockIncomes,
    });

    // const handleClose = (e) => {
    //     e.stopPropagation();
    //     onTurnOffDialog();
    // }

    const handleOk = (e) => {
        e.stopPropagation();
        setLoading(true);
        const mapIncomeIds = map(incomeSureListSelected, (o) => o?.id);
        const params = {
            incomeIds: mapIncomeIds,
        };
        run(params)
            .then((response) => {
                if (response.status === 200) {
                    finshParams?.onFinish();
                    dispatch(fuseActions.showMessage({message: "_.Lock income success", variant: "success"}));
                    onTurnOffDialog();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }


    return (
        <>
            <RootDialogController
                open={dialogProps.open}
                onClose={()=>{ onTurnOffDialog() }}
            >
                <DialogTitle>
                    {t("lock income")}
                </DialogTitle>
                <div className={loading ? "loading" : ""}>
                    <DialogContent>
                        <Typography variant="body2" color="textSecondary">
                            {t("Do you want to lock the selected incomes?")}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {/*<Button variant={"contained"} color={"secondary"} size={"small"} onClick={handleClose}>*/}
                        {/*    {t("close")}*/}
                        {/*</Button>*/}
                        <Button variant={"contained"} color={"primary"} size={"small"} onClick={handleOk}>
                            {t("lock")}
                        </Button>
                    </DialogActions>
                </div>
            </RootDialogController>
        {/*<Dialog {...dialogProps}>*/}
        {/*    <DialogTitle>*/}
        {/*        {t("lock income")}*/}
        {/*    </DialogTitle>*/}
        {/*    <div className={loading ? "loading" : ""}>*/}
        {/*        <DialogContent>*/}
        {/*            <Typography variant="body2" color="textSecondary">*/}
        {/*                {t("Do you want to lock the selected incomes?")}*/}
        {/*            </Typography>*/}
        {/*        </DialogContent>*/}
        {/*        <DialogActions>*/}
        {/*            <Button variant={"contained"} color={"secondary"} size={"small"} onClick={handleClose}>*/}
        {/*                {t("close")}*/}
        {/*            </Button>*/}
        {/*            <Button variant={"contained"} color={"primary"} size={"small"} onClick={handleOk}>*/}
        {/*                {t("lock")}*/}
        {/*            </Button>*/}
        {/*        </DialogActions>*/}
        {/*    </div>*/}
        {/*</Dialog>*/}
            </>
    );
}

export default NewLockIncomesDialog;



