
import {lazy} from "react";

export const LockIncomeConfig = {
    settings: {
        layout: {},
    },
    routes: [
        {
            path: "/lockIncomes",
            component: lazy(() => import("./Page")),
        },
    ],
};
