import {FuseLayout} from "@fuse";
import {Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import history from "../@history";
import {useAuth} from "./auth/AuthProvider";
import {useTranslation} from 'react-i18next';

export const isSecurePath = (pathname?: string) => {
    const paths = ["/login", "/register", "/support", "/forgot-password"];
    return !paths.includes(pathname || history.location.pathname);
};

const AppContent = () => {

    const {t} = useTranslation(["_"]);

    // const dispatch = useDispatch() as (action: any) => void;

    const {loading} = useAuth();

    // const [loading, setLoading] = useState(false);

    // const [error, setError] = useState();

    // const init = () => {
    //     /**
    //      *  if there is token in localStorage we need to check that
    //      *         if checkToken is successful
    //      *          and if it's login or register page we need to send it to 'lastPathname' instead of login page
    //      *          also we should set user data in Redux
    //      *          else if it's not successful
    //      *          if it's not login page we need to push it to login page and logout user and set last path
    //      *          if it's login page we need to remove user data
    //      *          if result is refresh
    //      *          we need to just refresh token in localStorage
    //      *          else if there is no token in localStorage
    //      *          if it's login or register page we don't need to push anywhere
    //      *         if it's not login page we need to push it to login page
    //      */
    //
    //     const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    //
    //     if (!(query.from === "APP") && !!window.Notification)
    //         Notify.requestPermission(
    //             () => {},
    //             () => {}
    //         );
    //
    //     const tokenFromStorage = localStorage.getItem("token");
    //     const token = tokenFromStorage || query.token;
    //
    //     if (token !== undefined && token !== "" && token !== null) {
    //         setLoading(true);
    //         axios({
    //             method: "get",
    //             url: `${baseURL}/${userPrefix}/checkToken`,
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 token: token,
    //             },
    //         })
    //             .then((res) => {
    //                 if (res.data.result === "valid" || res.data.result === "refresh") {
    //                     login(res.data.token || token).then(() => {
    //                         setLoading(false);
    //                     });
    //                     // getSettings().then((settings) => {
    //                     //     dispatch(setUserData());
    //                     //     if (settings && settings.language) {
    //                     //         localStorage.setItem("i18nextLng", settings.language);
    //                     //         i18n.changeLanguage(settings.language);
    //                     //     }
    //                     //     history.push({pathname: "/dashboard"});
    //                     // });
    //                 } else {
    //                     dispatch(logoutUser());
    //                     setLoading(false);
    //                 }
    //             })
    //             .catch((e) => {
    //                 setError(e);
    //                 setLoading(false);
    //             });
    //     } else {
    //         dispatch(logoutUser());
    //         setLoading(false);
    //         // localStorage.clear();
    //         // // localStorage.setItem("lastPathName", history.location.pathname + history.location.search);
    //         // history.push({pathname: "/login"});
    //     }
    // };
    //
    // useEffect(() => {
    //     if (isSecurePath()) {
    //         init();
    //     }
    // }, []);

    return loading ? (
        <div className={"flex my-36 flex-1 flex-col items-center justify-center "}>
            <div className="logo">
                <img width="128" src="assets/images/logos/logo128.png" alt="logo" />
            </div>
            <Typography variant={"h6"} className={"text-white mb-20"}>
                {t("Loggin in...")}
            </Typography>
            <CircularProgress size={50} color="secondary" />
        </div>
    ) : (
        <FuseLayout />
    );
};

export default AppContent;
