import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Input,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {red} from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {MyTooltip} from "@ui";
import {useMemoizedFn, useSetState} from "ahooks";
import * as fuseActions from "@fuseActions";
import BigNumber from "bignumber.js";
import _, {toNumber} from 'lodash';
import {chain} from "mathjs";
import numeral from "numeral";
import {useEffect, useMemo} from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {withRouter} from "react-router-dom";
import {useLocaleStore, useMUIDialog} from "../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../@fuse/state/constant";
import axios, {financialPrefix} from "../../../../myaxios";
import TableEmpty from "../../../../utils/empty_placeholder/TableEmpty";
import {AsyncSelectQuery, MyPriceLabel} from "../../../../utils/RootComponents";
import json from "../translate/income.json";
import {useIncomeCommonUtils} from "../utils/state";
import {OrderLabel} from "../utils/utils";
import {useAddIncomeOrders} from "./hooks";

function DeleteIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
            />
        </SvgIcon>
    );
}

const selectAllOption = {
    value: "<SELECT_ALL>",
    label: (
        <div className={"flex flex-1 w-full justify-end"}>
            <Button>{<Translate id={"income.select all"} />}</Button>
        </div>
    )
};

const initSelected = {
    loading: false,
    searchLoading: false,
    inputValue: "",
    overAmountDistributeTips: "",
    searchOrders: [],
    options: [],
    orderValue: "",
    avalableAmount: 0,
    anchorEl: null,
    searchBy: "keyword",
    openOrders: false,
    open: false,
    selectedOrders: [],
    isFetching: false
};

function NewOrderDistributeDialog(props) {
    const {dialogProps, onTurnOffDialog, finshParams} = useMUIDialog({
        registry: GDialogRegistries.orderDistribute
    });
    const {run, isMutating} = useAddIncomeOrders();
    const {currentIncome, onChangeCurrentIncome} = useIncomeCommonUtils();
    const [state, setState] = useSetState(initSelected);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        props.addTranslation(json);
        console.log("NewOrderDistributeDialog > currentIncome", currentIncome);
        initDatas();
        return () => {
            onChangeCurrentIncome(null);
        };
    }, []);

    useEffect(() => {
        if (state.selectedOrders && state.selectedOrders.length > 0) {
            checkOverAmount();
        }
    }, [state.selectedOrders]);

    const initDatas = useMemoizedFn(() => {
        let preAmounts = currentIncome?.availableAmount;
        setState({
            avalableAmount: preAmounts
        });
    });

    const checkOverAmount = useMemoizedFn(() => {
        const totalAmount = _.isArray(state.selectedOrders) === true ? sumArray(state.selectedOrders) : 0;
        const originNum = new BigNumber(currentIncome?.availableAmount);
        const minusNum = new BigNumber(totalAmount);
        const balance = originNum.minus(minusNum).toNumber();
        setState({
            avalableAmount: balance
        });
        if (totalAmount > currentIncome?.availableAmount) {
            setState({
                overAmountDistributeTips: "income.The amount allocated has exceeded this income"
            });
        } else {
            setState({
                overAmountDistributeTips: ""
            });
        }
    });

    const sumArray = (arr) => {
        let sum = new BigNumber("0");
        arr.forEach((obj, index, array) => {
            let tempAmount = new BigNumber("0");
            if (obj.order.distributeAmount !== "" && obj.order.distributeAmount) {
                tempAmount = new BigNumber(_.toNumber(obj.order.distributeAmount)?.toFixed(2));
            }
            sum = sum.plus(tempAmount);
        });
        return sum.toNumber();
    };

    const orderListRequest = useMemoizedFn((params) => {
        return axios.get(`${financialPrefix}/orderList`, {params});
    });

    const createOrderLabel = useMemoizedFn((datas) => {
        return new Promise((resolve, reject) => {
            const wrapOrders = datas
                ? datas
                .filter((temp) => {
                    return !state.selectedOrders.map((obj) => obj.order.orderCode).includes(temp.orderCode);
                })
                .map((option) => ({
                    order: {...option, distributeAmount: ""},
                    value: option.orderCode,
                    disabled: option.availableAmount === 0 || option.availableAmount < 0,
                    label: <OrderLabel order={option} />
                }))
                : [];
            setState({
                options: wrapOrders
            });
            resolve([selectAllOption, ...wrapOrders]);
        });
    });

    const handleSelectOrder = useMemoizedFn((value) => {
        if (value && value !== "") {
            if (value.value === selectAllOption.value) {
                const tempOrders = state.options.filter((item) => item.order.availableAmount > 0);
                setState((prestate, props) => ({
                    selectedOrders: [...tempOrders]
                }));
            } else {
                setState((prestate, props) => ({
                    selectedOrders: [value, ...state.selectedOrders]
                }));
            }
        }
    });

    const handleDistributeAmount = (order) => (e) => {
        setState({
            selectedOrders: state?.selectedOrders?.map((item) => {
                let tObj = {...item};
                if (item.order.orderCode === order.orderCode) {
                    // tObj.order.distributeAmount = _.toNumber(e.target.value)?.toFixed(2) ;
                    tObj.order.distributeAmount = e.target.value;
                    return tObj;
                }
                return tObj;
            })
        });
    };

    /**
     * @description - 当只有一个分配订单时，选择当前回款最大金额
     */
    const handleDistributeAllMoney = (row) => (e) => {
        e.stopPropagation();
        const changed = state?.selectedOrders?.map((item) => {
            let tObj = {...item};
            if (item.order.orderCode === row.orderCode) {
                tObj.order.distributeAmount =  _.toNumber(currentIncome.availableAmount)?.toFixed(2) ;
                return tObj;
            }
            return tObj;
        });
        setState({
            ...state,
            selectedOrders: changed
        });
    };

    /**
     * @description - 将分配金额平均分配给所有订单
     * */
    const handleDistributeAverageMoney = (row) => (e) => {
        e.stopPropagation();
        const averageMoney = chain(currentIncome.availableAmount).divide(state.selectedOrders.length).done();
        if (row) {
            const changed = state?.selectedOrders?.map((item) => {
                let tObj = {...item};
                if (item.order.orderCode === row.orderCode) {
                    tObj.order.distributeAmount = _.toNumber(averageMoney)?.toFixed(2)  ;
                    return tObj;
                }
                return tObj;
            });
            setState({
                ...state,
                selectedOrders: changed
            });
        }
    };

    const handleDeleteAOderFromOrders = (row) => {
        setState({
            selectedOrders: state?.selectedOrders?.filter((item) => item.order.orderId !== row.orderId)
        });
    };

    const handleClose = useMemoizedFn((e) => {
        e.stopPropagation();
        onTurnOffDialog();
    });

    const handleSure = useMemoizedFn((e) => {
        e.stopPropagation();
        const path = location.pathname;
        const {selectedOrders} = state;
        setState({
            loading: true
        });
        const params = {
            incomeId: currentIncome.id,
            orders: selectedOrders.map((item) => {
                return {
                    clientId: item.order.clientId,
                    clientName: item.order.clientName,
                    orderCode: item.order.orderCode,
                    orderId: item.order.orderId,
                    projectId: item.order.projectId,
                    projectCode: item.order.projectCode,
                    projectName: item.order.projectName,
                    amount: _.toNumber(item.order.distributeAmount).toFixed(2)
                };
            })
        };
        run(params)
        .then((res) => {
            if (res.status === 200) {
                finshParams?.onFinish();
                dispatch(
                    fuseActions.showMessage({
                        variant: "success",
                        message: "message.Oders distribute success!"
                    })
                );
                onTurnOffDialog();
            }
        })
        .finally(() => {
            setState({
                loading: false
            });
        });
    });

    const disabledSubmit = useMemo(() => {
        const {selectedOrders, loading} = state;
        let disabled = false;
        let tempTotalAmount = new BigNumber("0");
        disabled = loading === true;
        if (selectedOrders.length === 0 || selectedOrders === "") {
            disabled = true;
        }
        if (selectedOrders instanceof Array) {
            selectedOrders.map((item) => {
                if (item.order.distributeAmount) {
                    // tempTotalAmount = math.chain(math.bignumber(tempTotalAmount)).add(math.bignumber(item.order.distributeAmount !== "" ? item.order.distributeAmount : 0)).done();
                    const distribuNum = new BigNumber(
                        `${item.order.distributeAmount !== "" ? _.toNumber(item.order.distributeAmount)?.toFixed(2)  : 0}`
                    );
                    tempTotalAmount = tempTotalAmount.plus(distribuNum);
                    if (_.toNumber(item.order.distributeAmount) > _.toNumber(item.order.availableAmount)) {
                        disabled = true;
                    }
                    if (item.order.distributeAmount === "" || item.order.distributeAmount === undefined) {
                        disabled = true;
                    }
                } else {
                    disabled = true;
                }
            });
        } else {
            disabled = true;
        }
        if (tempTotalAmount.toNumber() > _.toNumber(currentIncome?.availableAmount)) {
            disabled = true;
        }
        return disabled;
    }, [state, currentIncome?.availableAmount]);

    return (
        <Dialog {...dialogProps}>
            <DialogTitle>
                <div className={"flex flex-row justify-between items-center"}>
                    <Typography variant="h6">
                        {<Translate id={"income.Revenue distribution to orders"} />}
                        {" : "}
                        {currentIncome?.transferName}
                    </Typography>
                    <Typography className={"inline-flex flex-row items-center"} variant="subtitle1">
                        {<Translate id={"income.Distributable amount: "} />}
                        <MyPriceLabel price={state.avalableAmount} />
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent className={"flex flex-1 min-h-512 overflow-y-scroll"}>
                <div className={state.loading ? "loading flex flex-1 w-full" : "flex flex-1 w-full"}>
                    <div className={"flex flex-1 w-full"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AsyncSelectQuery
                                    name={"keyword"}
                                    defaultValue={state?.selectedOrders}
                                    onChangeValue={handleSelectOrder}
                                    onCreateSelectValue={createOrderLabel}
                                    dataSourceRequest={orderListRequest}
                                    standaloneMode
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Table size={"small"} className="w-full mt-3">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">{<Translate id={"income.orderCode"} />}</TableCell>
                                            <TableCell align="right">
                                                {<Translate id={"income.projectCode"} />}
                                            </TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                {<Translate id={"income.clientName"} />}
                                            </TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                {<Translate id={"income.invoiceAmount(￥)"} />}
                                            </TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                {<Translate id={"income.Max Amount(￥)"} />}
                                            </TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                {<Translate id={"income.Quota(￥)"} />}
                                            </TableCell>
                                            <TableCell align="right" className="text-nowrap">
                                                {<Translate id={"income.action"} />}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={"h-auto overflow-y-auto"}>
                                        {state.selectedOrders && state.selectedOrders.length > 0 ? (
                                            state.selectedOrders.map((row) =>
                                                row.order ? (
                                                    <TableRow key={row.order.orderId}>
                                                        <TableCell align="left" className="text-nowrap">
                                                            {row.order.orderCode}
                                                        </TableCell>
                                                        <TableCell align="center" className="text-nowrap">
                                                            {row.order.projectCode}
                                                        </TableCell>
                                                        <TableCell align="right" className="text-nowrap">
                                                            {row.order.clientName}
                                                        </TableCell>
                                                        <TableCell align="right" className="text-nowrap">
                                                            {numeral(row.order.invoiceAmount).format("0,0.00")}
                                                        </TableCell>
                                                        <TableCell align="right" className="text-nowrap">
                                                            {numeral(row.order.availableAmount).format("0,0.00")}
                                                        </TableCell>
                                                        <TableCell align="right" className="text-nowrap">
                                                            {
                                                                <Translate>
                                                                    {({translate}) => (
                                                                        <Input
                                                                            placeholder={translate(
                                                                                "income.Distribute income here"
                                                                            )}
                                                                            className={
                                                                                state.overAmountDistributeTips === "" ||
                                                                                _.toNumber(row.order.distributeAmount) >
                                                                                _.toNumber(
                                                                                    row.order.availableAmount
                                                                                )
                                                                                    ? `flex flex-1 justify-end font-mono text-right`
                                                                                    : `flex flex-1 justify-end text-right font-mono text-red`
                                                                            }
                                                                            type={"number"}
                                                                            disableUnderline
                                                                            fullWidth
                                                                            key={row.order.orderId}
                                                                            error={
                                                                                state.overAmountDistributeTips !== "" ||
                                                                                _.toNumber(row.order.distributeAmount) >
                                                                                _.toNumber(
                                                                                    row.order.availableAmount
                                                                                )
                                                                            }
                                                                            helperText={
                                                                                state.overAmountDistributeTips !== ""
                                                                                    ? translate(
                                                                                        state.overAmountDistributeTips
                                                                                    )
                                                                                    : ""
                                                                            }
                                                                            value={row.order.distributeAmount}
                                                                            inputProps={{style: {textAlign: "right"}}}
                                                                            endAdornment={
                                                                                <div
                                                                                    className={
                                                                                        "flex flex-row justify-center items-center"
                                                                                    }
                                                                                >
                                                                                    {state.selectedOrders.length ===
                                                                                    1 ? (
                                                                                        <MyTooltip
                                                                                            title={translate(
                                                                                                "income.order-availableAmount"
                                                                                            )}
                                                                                        >
                                                                                            <Chip
                                                                                                size="small"
                                                                                                label={
                                                                                                    currentIncome?.availableAmount
                                                                                                }
                                                                                                clickable
                                                                                                onClick={handleDistributeAllMoney(
                                                                                                    row.order
                                                                                                )}
                                                                                            />
                                                                                        </MyTooltip>
                                                                                    ) : (
                                                                                        <div
                                                                                            className={
                                                                                                "flex flex-row justify-center items-center"
                                                                                            }
                                                                                        >
                                                                                            <MyTooltip
                                                                                                title={translate(
                                                                                                    "income.average-availableAmount"
                                                                                                )}
                                                                                            >
                                                                                                <Chip
                                                                                                    size="small"
                                                                                                    className={"mx-3"}
                                                                                                    label={numeral(
                                                                                                        chain(
                                                                                                            currentIncome?.availableAmount
                                                                                                        )
                                                                                                        .divide(
                                                                                                            state
                                                                                                                .selectedOrders
                                                                                                                .length
                                                                                                        )
                                                                                                        .done()
                                                                                                    ).format("0,0.00")}
                                                                                                    clickable
                                                                                                    onClick={handleDistributeAverageMoney(
                                                                                                        row.order
                                                                                                    )}
                                                                                                />
                                                                                            </MyTooltip>
                                                                                            <MyTooltip
                                                                                                title={translate(
                                                                                                    "income.order-availableAmount"
                                                                                                )}
                                                                                            >
                                                                                                <Chip
                                                                                                    size="small"
                                                                                                    label={
                                                                                                        currentIncome?.availableAmount ?
                                                                                                            toNumber(currentIncome?.availableAmount).toFixed(2) :
                                                                                                            0.00
                                                                                                    }
                                                                                                    clickable
                                                                                                    onClick={handleDistributeAllMoney(
                                                                                                        row.order
                                                                                                    )}
                                                                                                />
                                                                                            </MyTooltip>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            onChange={handleDistributeAmount(row.order)}
                                                                        />
                                                                    )}
                                                                </Translate>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <MyTooltip title={<Translate id={"income.delete order"} />}>
                                                                <IconButton
                                                                    color="primary"
                                                                    aria-controls="simple-menu-for-edit-Edit contract"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        handleDeleteAOderFromOrders(row.order);
                                                                    }}
                                                                >
                                                                    <DeleteIcon style={{color: red[500]}} />
                                                                </IconButton>
                                                            </MyTooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <></>
                                                )
                                            )
                                        ) : (
                                            // <TableRow key={"sdfgdsfgsdfghdsfgh"}>
                                            //     <TableCell colSpan={6} align={"center"}>
                                            //         <Typography
                                            //             variant="button"
                                            //             display="block"
                                            //             className="text-center"
                                            //         >
                                            //             {<Translate id={"income.No data"} />}
                                            //         </Typography>
                                            //     </TableCell>
                                            // </TableRow>
                                            <TableEmpty />
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={"contained"}>
                    {<Translate id={"_.cancel"} />}
                </Button>

                <Button disabled={disabledSubmit} onClick={handleSure} color={"secondary"} variant={"contained"}>
                    {<Translate id={"_.sure"} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withLocalize(withRouter(NewOrderDistributeDialog));
