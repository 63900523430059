import {create} from 'zustand';
import {subscribeWithSelector} from 'zustand/middleware';
import {LockIncomesSlice} from './constant';
import {createLockIncomeSlice} from './createLockIncomeSlice';

export const useLockIncomeUtils = create<
    LockIncomesSlice
>()(subscribeWithSelector((...a)=> ({
    ...createLockIncomeSlice(...a),
})));
