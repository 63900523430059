import {Box, Button, Checkbox, Collapse} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {Rating} from "@material-ui/lab";
import {MyTooltip} from "@ui";
import {useLocalStorageState, useMemoizedFn, useMount} from "ahooks";
import _ from "lodash";
import {chain} from "mathjs";
import axios, {financialPrefix} from "myaxios";
import numeral from "numeral";
import {Fragment, useContext, useEffect, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {useLocaleStore, useMUIDialog, useRouterSwitch} from "../../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../../@fuse/state/constant";
import { showMessage } from "../../../../../store/actions";
import {useList as useIncomeSureList} from "../../../IncomeSureManager/hooks";
import {useList} from "../../../MyIncomes/hooks";
import {useMyIncomeStoreUtils} from "../../../MyIncomes/state";
import {useIncomeStore} from "../../state";
import {INCOME_LOCAL_ID, MatchingOrderContext} from "../../state/constant";

const initState = {
    selectedOrdersMenu: null,
    opens: {},
    filterDatas: [],
    currentMatchDegree: 5,
    showGroup: {},
    fiveStarSelected: [],
    disableCheck: false,
    overAvailableAmountError: "",
};

function NewSubContent(props) {
    const {finshParams, onTurnOffDialog} = useMUIDialog({
        registry: GDialogRegistries.matchingOrder,
    });

    const selectedMatchingDistributeActions = useMUIDialog({
        registry: GDialogRegistries.selectedMatchingDistribute,
        maxWidth: "md",
    });

    const dispatch = useDispatch();

    const context = useContext(MatchingOrderContext);

    const {language} = useLocaleStore();

    const {pathname, params} = useRouterSwitch();

    const [incomeId, setIncomeId] = useLocalStorageState(INCOME_LOCAL_ID);

    const myIncomesQueryObj = useMyIncomeStoreUtils();

    const incomeSureQueryObj = useMyIncomeStoreUtils();

    const myIncomesRequest = useList();

    const incomeSureRequest = useIncomeSureList();

    const detailRequest = context.detailRequest;

    const matchingRequest = context.matchingRequest;

    const incomeDetail = detailRequest?.data?.data;

    const matchingOrders = matchingRequest?.data?.data;

    const {matchingOrdersCount, matchingOrdersSelected, onChangeMatchingOrdersSelected} = useIncomeStore();

    const [state, setState] = useState(initState);

    useMount(() => {
        initialFunc();
    });

    useEffect(() => {
        if (matchingOrdersSelected.length > 0) {
            checkTheOverAmount();
        }
    }, [matchingOrdersSelected]);

    const initialFunc = () => {
        let index = 5;
        _.forEach(matchingOrders, (item) => {
            if (item.orders.length > 0) {
                index = item.matchDegree;
            }
        });
        handleFilterClick(index);
    };

    const handleSelectAll = (e) => {
        e.stopPropagation();
        matchingOrdersSelected.length > 0 && onChangeMatchingOrdersSelected([]);
        if (matchingOrdersSelected.length === 0 || !matchingOrdersSelected) {
            const newSelected = getOrdersFromMatchingIncomes();
            onChangeMatchingOrdersSelected(newSelected);
        }
    };

    const handleCloseOrderMenue = () => {
        setState({
            ...state,
            selectedOrdersMenu: null,
        });
    };

    const handleFilterClick = (matchDegree) => {
        setState({
            ...state,
            currentMatchDegree: matchDegree,
            filterDatas: [],
        });
        _.forEach(matchingOrders, (value) => {
            state.matchDegree === matchDegree &&
                setState((preState) => ({
                    ...state,
                    filterDatas: value.orders.length > 0 ? [...preState.filterDatas, ...value.orders] : preState.filterDatas,
                }));
        });
    };

    const checkTheOverAmount = () => {
        let totalAmount = 0;
        matchingOrdersSelected.map((item) => {
            totalAmount = chain(totalAmount).add(_.toNumber(item.invoiceRequestAmount)).done();
        });
        if (_.toNumber(totalAmount) > _.toNumber(detailRequest?.data?.data?.availableAmount)) {
            setState({
                ...state,
                overAvailableAmountError: "income.The distribution amount should be less than the effective income amount",
            });
        } else {
            setState({
                ...state,
                overAvailableAmountError: "",
            });
        }
    };

    const onSelectAllClick = (e) => {
        if (matchingOrdersSelected.length > 0) {
            onChangeMatchingOrdersSelected([]);
        }
    };

    const handleInvoiceOrdersCheck = (row) => {
        // const {selectedAutoMatchings, selectAutoMatchingRevenues} = this.props;

        const selectedThis = isContained(matchingOrdersSelected, row.orders);

        if (selectedThis) {
            const res = matchingOrdersSelected.filter((item) => row.orders.map((t) => t.id).indexOf(item.id) === -1);
            onChangeMatchingOrdersSelected(res);
        } else {
            if (row.matchDegree === 5) {
                onChangeMatchingOrdersSelected(row.orders);
            } else {
                onChangeMatchingOrdersSelected([...matchingOrders, ...row.orders]);
            }
        }
    };

    const handleCheck = (event, n) => {
        const selectedIndex = matchingOrdersSelected.map((item) => item.id).indexOf(n.id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(matchingOrdersSelected, n);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(matchingOrdersSelected.slice(1));
        } else if (selectedIndex === matchingOrdersSelected.length - 1) {
            newSelected = newSelected.concat(matchingOrdersSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(matchingOrdersSelected.slice(0, selectedIndex), matchingOrdersSelected.slice(selectedIndex + 1));
        }
        onChangeMatchingOrdersSelected(newSelected);
    };

    const isContained = (arrayA, arrayB) => {
        let resArray = [];

        arrayB.forEach((item) => {
            if (arrayA.map((t) => t.id).indexOf(item.id) !== -1) {
                resArray.push(item);
            }
        });
        return resArray.length === arrayB.length;
    };

    const openSelectedOrdersMenu = (event) => {
        setState({selectedOrdersMenu: event.currentTarget});
    };

    const closeSelectedOrdersMenu = () => {
        setState({selectedOrdersMenu: null});
    };

    const toggleMore = (row) => {
        //matchDegree
        const {matchDegree} = row;
        const opens = state.opens;
        if (opens[matchDegree] === "table-row-im") delete opens[matchDegree];
        else opens[matchDegree] = "table-row-im";

        setState({
            ...state,
            opens: opens,
        });
    };

    const handleTheMatchDegreeCount = (matcgDegree) => {
        const filterArray = _.filter(matchingOrders, (item) => {
            return item.matchDegree === matcgDegree;
        });
        let resArray = [];
        _.forEach(filterArray, (item) => {
            if (item.orders.length > 0) {
                _.forEach(item.orders, (subItem) => {
                    resArray.push(subItem);
                });
            }
        });
        return resArray.length;
    };

    const getOrdersFromMatchingIncomes = () => {
        let globalOrders = [];
        _.forEach(matchingOrders, (item) => {
            if (item.orders.length > 0) {
                globalOrders = globalOrders.concat(item.orders);
            }
        });
        return globalOrders;
    };

    const handleExpandInvoiceGroup = (key) => {
        const open = state.showGroup;
        if (open[key] === "table-row-im") {
            delete open[key];
        } else {
            open[key] = "table-row-im";
        }
        setState({
            ...state,
            showGroup: open,
        });
    };

    const handleDistributeFiveStarToIncome = () => {
        const params = {
            incomeId: incomeId,
            orders: matchingOrdersSelected.map((item) => {
                return {
                    clientId: item.clientId,
                    clientName: item.clientName,
                    orderCode: item.orderCode,
                    orderId: item.orderId,
                    projectId: item.projectId,
                    projectCode: item.projectCode,
                    projectName: item.projectName,
                    amount: item.invoiceRequestAmount,
                };
            }),
        };
        axios.put(`${financialPrefix}/addIncomeOrders`, params).then(() => {
            finshParams?.onFinish();
            dispatch(
                showMessage({
                    variant: "success",
                    message: "message.Oders distribute success!",
                })
            );
            onTurnOffDialog();
        });
    };

    const handleRefreshForSelectedMatchingDistribute = useMemoizedFn(() => {
        const attchmentId = params?.attchmentId;
        pathname === "/incomes/manager/incomeSure" || attchmentId
            ? incomeSureRequest.run(incomeSureQueryObj.query).then(() => {})
            : myIncomesRequest.run(myIncomesQueryObj.query).then(() => {});
        const detailsParams = {id: incomeId};
        detailRequest.run(detailsParams).then(() => {});
    });

    return (
        <div className="flex flex-col flex-1">
            <Card className={"flex flex-row flex-1  mx-5 rounded-lg bg-gray-200 justify-between items-center p-3 py-5 min-h-50"}>
                <div className={"flex flex-col justify-center"}>
                    <div className={"flex-1"}>
                        <Typography variant="body2">{<Translate id={"income.Transaction header"} />}</Typography>
                    </div>
                    <div className={"flex-1 justify-end"}>
                        <Typography variant="subtitle2">{incomeDetail?.transferName}</Typography>
                    </div>
                </div>

                <div className={"flex flex-col justify-center"}>
                    <div className={"flex-1"}>
                        <Typography variant="body2">{<Translate id={"income.availableAmount"} />}</Typography>
                    </div>
                    <div className={"flex-1 justify-end"}>
                        <Typography variant="subtitle2" className={"text-right"}>
                            {numeral(incomeDetail?.availableAmount).format("0,0.00")}
                        </Typography>
                    </div>
                </div>
            </Card>
            <Card className="flex  flex-row flex-1  justify-between items-center mx-5 p-3 py-16">
                <div className={"flex flex-col justify-center w-full"}>
                    <div className="flex flex-1 w-full flex-row justify-between items-center">
                        <div className={"flex flex-row items-center"}>
                            <MyTooltip title={<Translate id={"income.select all"} />}>
                                <Checkbox
                                    // disabled={getOrdersFromMatchingIncomes().length === 0}
                                    indeterminate={
                                        matchingOrdersSelected.length > 0 && matchingOrdersSelected.length < getOrdersFromMatchingIncomes().length
                                    }
                                    checked={
                                        matchingOrdersSelected.length > 0 && matchingOrdersSelected.length === getOrdersFromMatchingIncomes().length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </MyTooltip>

                            <Translate>
                                {({translate}) => (
                                    <Typography variant="button" className={"mr-2"}>
                                        (
                                        {translate("income.selected with params", {
                                            number: matchingOrdersSelected.length,
                                        })}
                                        )
                                    </Typography>
                                )}
                            </Translate>
                        </div>

                        <div className={"flex flex-row items-center"}>
                            <Button
                                disabled={matchingOrdersSelected.length === 0 || !matchingOrdersSelected}
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleCloseOrderMenue();
                                    state.currentMatchDegree === 5
                                        ? handleDistributeFiveStarToIncome()
                                        : selectedMatchingDistributeActions.onTurnOnDialog({
                                              onFinish: handleRefreshForSelectedMatchingDistribute,
                                          });
                                }}>
                                {<Translate id="income.Distribute orders" />}
                            </Button>
                        </div>
                    </div>
                    <div className={"flex flex-1 flex-row items-center"}>
                        {state.overAvailableAmountError !== "" && (
                            <small className={"ml-5 text-red"}>{<Translate id={state.overAvailableAmountError} />}</small>
                        )}
                    </div>
                </div>
            </Card>

            <Card className={"flex flex-row flex-1 justify-between items-center p-3 py-5 mx-3"}>
                <MyTooltip
                    title={
                        <div className={"flex flex-row justify-between"}>
                            <div className={"flex"}>{<Translate id={"income.five star matchDegree count"} />}</div>
                            <div className={"flex ml-5"}>{handleTheMatchDegreeCount(5)}</div>
                        </div>
                    }>
                    <div className={"flex flex-row w-1/3 justify-center items-center"}>
                        <Button
                            className={state.currentMatchDegree === 5 ? "bg-gray-300" : ""}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleFilterClick(5);
                            }}
                            disabled={
                                _.indexOf(
                                    matchingOrders?.map((item) => item.matchDegree),
                                    5
                                ) === -1
                            }>
                            <Rating name="read-only-5-starsa" value={5} readOnly />
                        </Button>
                        (<small className={"flex items-center"}>{handleTheMatchDegreeCount(5)}</small>)
                    </div>
                </MyTooltip>

                <MyTooltip
                    title={
                        <div className={"flex flex-row justify-between"}>
                            <div className={"flex"}>{<Translate id={"income.four star matchDegree count"} />}</div>
                            <div className={"flex ml-5"}>{handleTheMatchDegreeCount(4)}</div>
                        </div>
                    }>
                    <div className={"flex flex-row w-1/3 justify-center items-center"}>
                        <Button
                            className={state.currentMatchDegree === 4 ? "bg-gray-300" : ""}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleFilterClick(4);
                            }}
                            disabled={
                                _.indexOf(
                                    matchingOrders?.map((item) => item.matchDegree),
                                    4
                                ) === -1
                            }>
                            <Rating name="read-only-5-starsa" value={4} readOnly />
                        </Button>
                        (<small className={"flex items-center"}>{handleTheMatchDegreeCount(4)}</small>)
                    </div>
                </MyTooltip>

                <MyTooltip
                    title={
                        <div className={"flex flex-row justify-between"}>
                            <div className={"flex"}>{<Translate id={"income.three star matchDegree count"} />}</div>
                            <div className={"flex ml-5"}>{handleTheMatchDegreeCount(3)}</div>
                        </div>
                    }>
                    <div className={"flex flex-row w-1/3 justify-center items-center"}>
                        <Button
                            className={state.currentMatchDegree === 3 ? "bg-gray-300" : ""}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleFilterClick(3);
                            }}
                            disabled={
                                _.indexOf(
                                    matchingOrders?.map((item) => item.matchDegree),
                                    3
                                ) === -1
                            }>
                            <Rating name="read-only-5-starsa" value={3} readOnly />
                        </Button>
                        (<small className={"flex items-center"}>{handleTheMatchDegreeCount(3)}</small>)
                    </div>
                </MyTooltip>
            </Card>
            <Card className="flex grow m-3 p-3 py-5">
                <Table className="w-full">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="right" padding={"none"} className="text-nowrap hidden md:table-cell">
                                {<Translate id={"income.Invoice Id"} />}
                            </TableCell>
                            <TableCell align="right" padding={"none"} className="text-nowrap hidden md:table-cell">
                                {<Translate id={"income.Transaction header"} />}
                            </TableCell>
                            <TableCell align="right" padding={"none"} className="text-nowrap hidden md:table-cell">
                                {<Translate id={"income.invoiceAmount"} />}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matchingOrders?.length > 0 ? (
                            matchingOrders
                                ?.filter((item) => item.matchDegree === state.currentMatchDegree)
                                ?.map((row, index) => {
                                    const tkey = row.invoice ? row.invoice.id : row.orders[0].id;
                                    console.log("打印map数据: incomeMatchings map", row);
                                    console.log("打印map数据: incomeMatchings tkey", tkey);
                                    return (
                                        <Fragment key={tkey}>
                                            <TableRow
                                                hover
                                                onClick={() => {
                                                    handleExpandInvoiceGroup(tkey);
                                                }}>
                                                <TableCell className="w-48 pl-4 sm:pl-12" padding={"none"}>
                                                    <Checkbox
                                                        key={tkey}
                                                        checked={isContained(matchingOrdersSelected, row.orders)}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => handleInvoiceOrdersCheck(row)}
                                                    />
                                                </TableCell>

                                                <TableCell
                                                    component="td"
                                                    align="right"
                                                    padding={"none"}
                                                    scope="row"
                                                    className="text-nowrap hidden md:table-cell">
                                                    {row.invoice ? row.invoice.id : "-"}
                                                </TableCell>

                                                <TableCell
                                                    component="td"
                                                    align="right"
                                                    padding={"none"}
                                                    scope="row"
                                                    className="text-nowrap hidden md:table-cell">
                                                    {row.invoice ? row.invoice.header : "-"}
                                                </TableCell>

                                                <TableCell
                                                    component="td"
                                                    align="right"
                                                    padding={"none"}
                                                    scope="row"
                                                    className="text-nowrap hidden md:table-cell">
                                                    {row.invoice ? numeral(row.invoice.amount).format("0,0.00") : "-"}
                                                </TableCell>

                                                <TableCell align="center" padding={"none"} component="td" scope="row">
                                                    <div className={"flex justify-center"}>
                                                        {state.showGroup[tkey] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${tkey} = ${_.random(100, 1000000000)}`}>
                                                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                                    <Collapse in={!!state.showGroup[tkey]} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                {<Translate id={"income.Orders"} />}
                                                                <small className={"ml-1"}>({row.orders.length})</small>
                                                            </Typography>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell />
                                                                        <TableCell padding={"none"} className={"hidden md:table-cell"}>
                                                                            {<Translate id={"income.orderCode"} />}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="right"
                                                                            padding={"none"}
                                                                            className="text-nowrap hidden md:table-cell">
                                                                            {<Translate id={"income.clientName"} />}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="right"
                                                                            padding={"none"}
                                                                            className="text-nowrap hidden md:table-cell">
                                                                            {<Translate id={"income.orderAmount(￥)"} />}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="right"
                                                                            padding={"none"}
                                                                            className="text-nowrap hidden md:table-cell">
                                                                            {<Translate id={"income.invoiceAmount(￥)"} />}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="right"
                                                                            padding={"none"}
                                                                            className="text-nowrap hidden md:table-cell">
                                                                            {<Translate id={"income.availableAmount(￥)"} />}
                                                                        </TableCell>
                                                                        <TableCell align="right" padding={"none"} className="text-nowrap">
                                                                            {<Translate id={"income.Distributed Amount(￥)"} />}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row.orders.length > 0 ? (
                                                                        row.orders.map((orderRow) => (
                                                                            <TableRow key={orderRow.id}>
                                                                                {state.currentMatchDegree !== 5 ? (
                                                                                    <TableCell className="w-48 pl-4 sm:pl-12" padding={"none"}>
                                                                                        <Checkbox
                                                                                            key={orderRow.id}
                                                                                            checked={
                                                                                                matchingOrdersSelected?.length > 0
                                                                                                    ? matchingOrdersSelected
                                                                                                          .map((item) => item.id)
                                                                                                          .indexOf(orderRow.id) !== -1
                                                                                                    : false
                                                                                            }
                                                                                            disabled={
                                                                                                _.toNumber(orderRow.availableAmount) < 0 ||
                                                                                                _.toNumber(orderRow.availableAmount) === 0
                                                                                            }
                                                                                            onClick={(event) => event.stopPropagation()}
                                                                                            onChange={(event) => handleCheck(event, orderRow)}
                                                                                        />
                                                                                    </TableCell>
                                                                                ) : (
                                                                                    <TableCell />
                                                                                )}

                                                                                <TableCell
                                                                                    component="td"
                                                                                    padding={"none"}
                                                                                    scope="row"
                                                                                    className="text-nowrap hidden md:table-cell">
                                                                                    {orderRow.orderCode}
                                                                                </TableCell>
                                                                                <MyTooltip
                                                                                    title={
                                                                                        <div className="flex justify-between flex-row items-center">
                                                                                            <Typography variant="body2" className="mr-5">
                                                                                                <small>
                                                                                                    <Translate id={"income.projectName: "} />
                                                                                                </small>
                                                                                            </Typography>
                                                                                            <Typography variant="button" display="block">
                                                                                                <strong>{orderRow.projectName}</strong>
                                                                                            </Typography>
                                                                                        </div>
                                                                                    }>
                                                                                    <TableCell
                                                                                        align="right"
                                                                                        padding={"none"}
                                                                                        component="td"
                                                                                        scope="row"
                                                                                        className="text-nowrap hidden md:table-cell">
                                                                                        {orderRow.clientName}
                                                                                    </TableCell>
                                                                                </MyTooltip>

                                                                                <TableCell
                                                                                    align="right"
                                                                                    component="td"
                                                                                    padding={"none"}
                                                                                    scope="row"
                                                                                    className="text-nowrap hidden md:table-cell">
                                                                                    {numeral(orderRow.orderAmount).format("0,0.00")}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    component="td"
                                                                                    padding={"none"}
                                                                                    scope="row"
                                                                                    className="text-nowrap hidden md:table-cell">
                                                                                    {numeral(orderRow.invoiceAmount).format("0,0.00")}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    component="td"
                                                                                    padding={"none"}
                                                                                    scope="row"
                                                                                    className="text-nowrap hidden md:table-cell">
                                                                                    {numeral(orderRow.availableAmount).format("0,0.00")}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    component="td"
                                                                                    padding={"none"}
                                                                                    scope="row"
                                                                                    className="text-nowrap">
                                                                                    {numeral(orderRow.invoiceRequestAmount).format("0,0.00")}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell colSpan={5}>{<Translate id={"income.no datas"} />}</TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    );
                                })
                        ) : (
                            <TableRow key={"sdfgdsfgsdfghdsfgh"}>
                                <TableCell colSpan={7} align={"center"}>
                                    <div className={"flex justify-center w-full items-center"}>
                                        <Typography variant="caption" align={"center"} className="text-center">
                                            {<Translate id={"income.No data"} />}
                                        </Typography>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Card>
        </div>
    );
}

export default withRouter(NewSubContent);
