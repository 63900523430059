import {Icon, IconButton, SvgIcon, Typography} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {LocalTimeStamp, MyAvatar, MyTooltip, NameLabel} from "@ui";
import {useMemoizedFn} from "ahooks";
import {useContext, useEffect, useMemo} from "react";
import {Translate} from "react-localize-redux";
import {useSelector} from "react-redux";
import {withRouter} from "react-router";
import {useMUIDialog, useRouterSwitch} from "../../../../../../@fuse/hooks";
import {GDialogRegistries} from "../../../../../../@fuse/state/constant";
import {ErrorButton} from "../../../../../../utils/utilComponents";
import {useIncomeSureUtils} from "../../../IncomeSureManager/state";
import {IncomeSureContext} from "../../../IncomeSureManager/state/constant";
import {useMyIncomeStoreUtils} from "../../../MyIncomes/state";
import {useIncomeCommonUtils} from "../../../utils/state";
import {useLockIncomeUtils} from '../../../../lockIncomes/state/useLockIncomeUtils';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab/Fab';

function RefundIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M18 11H6A2 2 0 0 0 4 13V21A2 2 0 0 0 6 23H18A2 2 0 0 0 20 21V13A2 2 0 0 0 18 11M18 17H6V14H18M17 5V10H15.5V6.5H9.88L12.3 8.93L11.24 10L7 5.75L11.24 1.5L12.3 2.57L9.88 5Z"
            />
        </SvgIcon>
    );
}

function DetailHeader(props) {
    const profile = useSelector(({user}) => user.profile);

    const context = useContext(IncomeSureContext);

    const {pathname} = useRouterSwitch();

    const {onChangeCurrentIncome} = useIncomeCommonUtils();

    const {onTurnOnDialog} = useMUIDialog({
        registry: GDialogRegistries.setIncomeUser,
        maxWidth: "sm"
    });

    const refundActions = useMUIDialog({
        registry: GDialogRegistries.refundAmount,
        maxWidth: "sm"
    });

    const changeUserMyIncomeActions = useMUIDialog({
        registry: GDialogRegistries.changeUserMyIncome,
        maxWidth: "sm"
    });

    const {isIncomeSure, onChangeIncomeSureListSelected} = useIncomeSureUtils();

    const {isMyIncome} = useMyIncomeStoreUtils();

    const {isLockIncome} = useLockIncomeUtils();

    useEffect(() => {
        return () => {
            onChangeIncomeSureListSelected([]);
        };
    }, []);

    const handleBack = useMemoizedFn((e) => {
        e.stopPropagation();
        context?.onClose();
    });

    const handleSetUser = useMemoizedFn((e) => {
        e.stopPropagation();
        if (isIncomeSure) {
            const params = context?.data ? [context?.data] : [];
            onChangeIncomeSureListSelected(params);
            onTurnOnDialog({
                onFinish: context?.onRefresh
            });
        }
        if (isMyIncome) {
            onChangeCurrentIncome(context?.data);
            changeUserMyIncomeActions.onTurnOnDialog({
                onFinish: context?.onRefresh
            });
        }
    });

    const handleRefresh = useMemoizedFn(() => {
        context?.onRefresh();
    });

    const refundMoneyAction = useMemoizedFn((e) => {
        e.stopPropagation();
        onChangeCurrentIncome(context?.data);
        refundActions.onTurnOnDialog({
            onFinish: handleRefresh
        });
    });

    const disabledSetUser = useMemo(() => {
        if (isIncomeSure || isLockIncome) {
            return context?.data?.status !== "ACTIVE";
        }
        if (isMyIncome) {
            return false;
        }
    }, [isIncomeSure, context?.data?.status, isMyIncome, isLockIncome]);


    return (
        <div className={"flex flex-row flex-1 items-center justify-between"}>
            <div className={"flex flex-row flex-1 items-center"}>
                <div className={"flex flex-row items-center"}>
                    <MyTooltip title={<Translate id={"_.back"} />}>
                        <IconButton onClick={handleBack}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    </MyTooltip>
                </div>
                <div className={"flex flex-col justify-center items-start"}>
                    <div className={"flex flex-row items-center"}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            <Typography className={"inline-flex text-grey-dark"}>
                                {pathname === "/myIncomes" ? <Translate id={"income.MyIncomes"} /> : pathname === "/lockIncomes" ? <Translate id={"income.Locked income"} /> : <Translate id={"income.incomeSures"} />}
                            </Typography>
                            <Typography className={"inline-flex text-lg"} variant={"button"}>
                                {<Translate id={"income.details"} />}
                            </Typography>
                        </Breadcrumbs>
                    </div>
                    <div className={"flex flex-row items-center ml-4"}>
                        <div className={"flex flex-row items-center"}>
                            <MyTooltip
                                title={
                                    disabledSetUser ? (
                                        <Translate id={"income.You can only allocate your return or no handler's return"} />
                                    ) : (
                                        <Translate id={"income.change user"} />
                                    )
                                }
                            >
                                <span>
                                    <button disabled={disabledSetUser} className={"mr-12"} onClick={handleSetUser}>
                                        <MyAvatar user={context?.data?.user} />
                                    </button>
                                </span>
                            </MyTooltip>
                        </div>
                        <div className="flex flex-row items-center ml-2">
                            <div className={"flex flex-col justify-center"}>
                                <div className={"flex flex-row items-center"}>
                                    {context?.data?.user && <NameLabel user={context?.data?.user} />}{" "}
                                    {context?.data?.user && <small>({context?.data?.user?.username})</small>}
                                </div>
                                <div className={"flex flex-row items-center"}>
                                    <small>{<Translate id={"income.create time:"} />}</small>
                                    <Typography variant={"button"}>
                                        <LocalTimeStamp datetime={context?.data?.timestamp} format={"YYYY-MM-DD"} showInsideTip={false} />
                                    </Typography>
                                </div>
                                <div className={"flex flex-row items-center"}>
                                    <small>{<Translate id={"income.Last active time:"} />}</small>
                                    <Typography variant={"button"}>
                                        <LocalTimeStamp format={"YYYY-MM-DD"} datetime={context?.data?.activeTime} showInsideTip={false} />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"flex flex-row items-center overflow-visible"}>
                {(isIncomeSure||isLockIncome) && context?.data?.type === "INCOME" && (
                    <MyTooltip title={<Translate id={"income.refund"} />}>
                        <ErrorButton startIcon={<RefundIcon />} onClick={refundMoneyAction}>
                            {<Translate id={"income.refund"} />}
                        </ErrorButton>
                    </MyTooltip>
                )}

            </div>
        </div>
    );
}

export default withRouter(DetailHeader);
