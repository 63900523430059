import {Dialog, DialogProps, IconButton} from '@material-ui/core';
import Fab from '@material-ui/core/Fab/Fab';
import CloseIcon from '@material-ui/icons/Close';
import {MyTooltip} from '@ui';
import {useTranslation} from 'react-i18next';


interface MyComponentOptions extends DialogProps{}


function RootDialogController(props: MyComponentOptions): JSX.Element {

    const {children,open,onClose,...rest} = props;

    // @ts-ignore
    const {t} = useTranslation("_");


    // @ts-ignore
    return (
        <Dialog
            open={open ?? false}
            {...rest}
            transitionDuration={300}
            scroll={"paper"}
            PaperProps={{
                style: { overflow: 'visible' }
            }}
            style={{
                overflowY: 'auto',     // 垂直滚动
                maxHeight: '90vh',     // 最大高度为视口高度的 80%
                padding: '30px',
            }}
            onClose={(e)=>{
                // @ts-ignore
                e.stopPropagation();
                // @ts-ignore
              onClose &&  onClose();
            }}
            keepMounted
            className={"overflow-hidden"}
            classes={{
                // @ts-ignore
                root: { borderRadius: 20 }
            }}
        >
            <>
                {children}
                <MyTooltip title={t("close")}>
                    <Fab
                        size={"small"}
                        style={{
                            position: "absolute",
                            zIndex: 999999999999,
                            right: -20,
                            top: -20,
                            overflow: "visible",
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            // @ts-ignore
                            onClose &&  onClose();
                        }}
                    >
                        <IconButton>
                            <CloseIcon />
                        </IconButton>
                    </Fab>
                </MyTooltip>
            </>
        </Dialog>
    );
}

export default RootDialogController;

