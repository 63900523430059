
import { forwardRef, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Dialog, DialogContent, Typography } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Translate } from "react-localize-redux";
import { useMemoizedFn } from "ahooks";
import { isMobile } from "react-device-detect";
import Fade from '@material-ui/core/Fade';

interface Version {
    current: string;
    new: string;
}

// 使用 const 定义常量
const DEFAULT_VERSION = "0.0.0";
const POLLING_INTERVAL = 30 * 60 * 1000; // 30分钟
const VERSION_META_NAME = "version_no";

const Transition = forwardRef((props, ref) => (
    <Fade ref={ref} {...props} />
));

const AutoUpgradeDialog: React.FC = () => {
    // 使用单个 ref 对象管理所有 ref
    const refs = useRef<{
        version: Version;
        env: string | null;
        timer: NodeJS.Timer | null;
    }>({
        version: {
            current: DEFAULT_VERSION,
            new: DEFAULT_VERSION
        },
        env: null,
        timer: null
    });

    const [visible, setVisible] = useState(false);

    // 提取版本号获取逻辑
    const getVersionFromMeta = (element: Document | HTMLElement = document): string => {
        const metaList = element.querySelectorAll("meta");
        let version = DEFAULT_VERSION;

        metaList.forEach(item => {
            if (item.getAttribute("name") === VERSION_META_NAME) {
                version = item.getAttribute("content") || DEFAULT_VERSION;
            }
        });

        return version;
    };

    // 获取最新版本
    const fetchNewVersion = useMemoizedFn(async () => {
        try {
            const timestamp = Date.now();
            const response = await axios.get(`${window.location.origin}?time=${timestamp}`);

            const parser = new DOMParser();
            const doc = parser.parseFromString(response.data, "text/html");
            const newVersion = getVersionFromMeta(doc);

            refs.current.version.new = newVersion;

            if (refs.current.env === "development") return;

            const shouldShowDialog = newVersion !== refs.current.version.current && !visible;
            const shouldHideDialog = newVersion === refs.current.version.current && visible;

            setVisible(shouldShowDialog);
            if (shouldHideDialog) setVisible(false);

        } catch (error) {
            console.error("Failed to fetch new version:", error);
        }
    });

    const timerStart = useMemoizedFn(() => {
        refs.current.timer = setInterval(fetchNewVersion, POLLING_INTERVAL);
    })

    const timerClear = useMemoizedFn(() => {
        if (refs.current.timer) {
            // @ts-ignore
            clearInterval(refs.current.timer);
            refs.current.timer = null;
        }
    })


    // 版本检查
    const checkVersion = useMemoizedFn(async () => {
        refs.current.version.current = getVersionFromMeta();
        await fetchNewVersion();
    });

    // 可见性变化处理
    const handleVisibilityChange = useMemoizedFn(() => {
        if (!document.hidden) {
            // @ts-ignore
            timerStart();
            checkVersion();
        } else {
            // @ts-ignore
            timerClear();
        }
    });

    // 更新处理
    const handleUpdate = useMemoizedFn(() => {
        // @ts-ignore
        window.location.reload(true);
        if (isMobile) {
            window.postMessage("exitApp", "*");
        }
        setVisible(false);
    });

    useEffect(() => {
        refs.current.env = process.env.NODE_ENV;
        checkVersion();
        // @ts-ignore
        timerStart();

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            // @ts-ignore
            timerClear();
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    // @ts-ignore
    // @ts-ignore
    return (
        <Dialog
            // @ts-ignore
            TransitionComponent={Transition}
            fullWidth
            maxWidth="sm"
            open={visible}
            aria-labelledby="version-update-dialog-title"
        >
            <DialogTitle id="version-update-dialog-title">
                <Translate id="_.tips" />
            </DialogTitle>

            <DialogContent>
                <div className="flex flex-row justify-center items-center">
                    <Typography variant="body1" className="text-center">
                        <Translate id="_.New version detected, please click update!" />
                    </Typography>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => setVisible(false)} color="primary">
                    <Translate id="_.cancel" />
                </Button>
                <Button onClick={handleUpdate} color="secondary">
                    <Translate id="_.update" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AutoUpgradeDialog;
